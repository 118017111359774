<template>
  <div style="width: 100%; height: 100%">
    <echarts :chartId="chartId" :options="options"></echarts>
  </div>
</template>
<script>
import echarts from "@/components/echarts/main.vue";
export default {
  name: "pie-chart",
  components: { echarts },
  props: {
    chartId: {
      type: String,
      default: "chartId" + Math.floor(Math.random() * 90) + 10,
    },
    chartData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    chartName: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    options: {},
  }),
  created() {
    this.options = {
      tooltip: {
        trigger: "item",
      },
      title: {
        text: this.chartName,
        left: "center",
        top: "center",
      },
      series: [
        {
          name: "",
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          data: this.chartData,
        },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
</style>