<template>
  <div class="home-page">
    <div class="com-top">
      <div class="total-box" v-for="item in topTatalData" :key="item.id">
        <div class="title">{{ item.title }}</div>
        <div class="num">{{ item.num }}</div>
      </div>
    </div>
    <div class="com-bottom">
      <div class="line">
        <div class="item padding-right24">
          <line-chart
            chartId="terminal"
            v-if="terminalMonthChart.show"
            title="日在线设备数(近一个月)"
            :xAxisData="terminalMonthChart.xData"
            :yAxisData="terminalMonthChart.yData"
            unit="个"
          ></line-chart>
        </div>
        <div class="item">
          <line-chart
            v-if="receiveMonthChart.show"
            chartId="receiveData"
            :xAxisData="receiveMonthChart.xData"
            :yAxisData="receiveMonthChart.yData"
            unit="包"
            title="日接收数据量(近一个月)"
          ></line-chart>
        </div>
      </div>
      <div class="bottom-dashed">
        <div class="top-condition">
          接入网关运行情况
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="line padding-left24 padding-right24">
          <div class="item">
            <pie-chart
              chartId="accessGateway1"
              :chartData="terminalTotalData"
              chartName="设备总数"
            ></pie-chart>
          </div>
          <div class="item">
            <pie-chart
              chartId="accessGateway2"
              :chartData="alarmTotalData"
              chartName="报警总数"
            ></pie-chart>
          </div>
          <div class="item">
            <line-chart
              v-if="showTerminalSevenDayChart"
              chartId="terminal7"
              :xAxisData="terminalSevenX"
              :yAxisData="terminalSevenY"
              unit="小时"
              title="设备七日内日均在线时长"
            ></line-chart>
          </div>
        </div>
        <div class="line padding-left24 padding-right24">
          <div class="item">
            <line-chart
              chartId="cpu"
              title="CPU"
              v-if="showGatewayLoadChart"
              :xAxisData="gatewayLoadX"
              :yAxisData="gatewayLoadY"
            ></line-chart>
          </div>
          <div class="item padding-left24 padding-right24">
            <line-chart
              chartId="memory"
              title="内存"
              v-if="showGatewayLoadChart"
              :xAxisData="gatewayLoadX"
              :yAxisData="gatewayLoadY"
            ></line-chart>
          </div>
          <div class="item">
            <line-chart
              chartId="dataPacket"
              title="接收数据包"
              v-if="showGatewayLoadChart"
              :xAxisData="gatewayLoadX"
              :yAxisData="gatewayLoadY"
            ></line-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from "../components/home/line-chart";
import PieChart from "../components/home/pie-chart";
import {
  getTerminalSevenDayData,
  queryMonthDataTotal,
  getGatewayLoadData,
  getDeviceTotalData,
  getDeviceActiveTotal,
  queryAssetsTotal,
  queryGatewayTotal,
  queryGatewayActiveTotal,
  queryMonthDeviceTotal,
} from "@/api/home/home.js";

export default {
  name: "index",
  components: { LineChart, PieChart },
  data() {
    return {
      topTatalData: [
        {
          title: "设备总数",
          num: 0,
        },
        {
          title: "在线设备数",
          num: 0,
        },
        {
          title: "资产总数",
          num: 0,
        },
        {
          title: "网关总数",
          num: 0,
        },
        {
          title: "运行网关数",
          num: 0,
        },
      ],
      options: [
        {
          value: "1",
          label: "MOTT接入网关",
        },
        {
          value: "2",
          label: "接入网关二",
        },
        {
          value: "3",
          label: "接入网关三",
        },
      ],
      value: "",
      showTerminalSevenDayChart: false,
      terminalSevenX: [],
      terminalSevenY: [],
      gatewayLoadX: [],
      gatewayLoadY: [],
      showGatewayLoadChart: false,
      terminalTotalData: [
        {
          name: "在线数",
          value: 1209,
        },
        {
          name: "离线数",
          value: 209,
        },
      ],
      alarmTotalData: [
        {
          name: "危险",
          value: 9,
        },
        {
          name: "重要",
          value: 20,
        },
        {
          name: "次要",
          value: 19,
        },
        {
          name: "警告",
          value: 9,
        },
        {
          name: "不确定",
          value: 2,
        },
      ],
      terminalMonthChart: {
        xData: [],
        yData: [],
        show: false,
      },
      receiveMonthChart: {
        xData: [],
        yData: [],
        show: false,
      },
    };
  },
  created() {
    this.getTerminalDay();
    this.getTerminalTotalData();
    this.getOnlineTerminalTotalData();
    this.getAssetsTotalData();
    this.getGatewayTotalData();
    this.getGatewayActiveTotalData();
    this.getMonthTerminalData();
    this.getMonthReceiveData();
  },
  methods: {
    getTerminalDay() {
      getTerminalSevenDayData().then((res) => {
        const xData = [],
          yData = [];
        res.result.data.forEach((item) => {
          const name = "2021-3-" + item.name;
          xData.push(name);
          yData.push(item.value);
        });
        this.terminalSevenX = xData;
        this.terminalSevenY = [
          {
            data: yData,
            type: "line",
          },
        ];
        this.showTerminalSevenDayChart = true;
      });
      getGatewayLoadData().then((res) => {
        const xData = [],
          yData1 = [],
          yData2 = [];
        res.result.data.forEach((item) => {
          let name;
          if (item.name < 10) {
            name = "10:0" + item.name;
          } else {
            name = "10:" + item.name;
          }
          xData.push(name);
          yData1.push(item.value1);
          yData2.push(item.value2);
        });
        this.gatewayLoadX = xData;
        this.gatewayLoadY = [
          {
            data: yData1,
            type: "line",
            name: "实例1",
          },
          {
            data: yData2,
            type: "line",
            name: "实例2",
          },
        ];
        this.showGatewayLoadChart = true;
      });
    },
    //获取设备总数
    getTerminalTotalData() {
      getDeviceTotalData().then((res) => {
        this.topTatalData[0].num = res.result.count;
      });
    },
    //获取在线设备总数
    getOnlineTerminalTotalData() {
      getDeviceActiveTotal().then((res) => {
        this.topTatalData[1].num = res.result.count;
      });
    },
    //获取资产总数
    getAssetsTotalData() {
      queryAssetsTotal().then((res) => {
        this.topTatalData[2].num = res.result.count;
      });
    },
    //获取网关总数
    getGatewayTotalData() {
      queryGatewayTotal().then((res) => {
        this.topTatalData[3].num = res.result.count;
      });
    },
    //获取运行网关总数
    getGatewayActiveTotalData() {
      queryGatewayActiveTotal().then((res) => {
        this.topTatalData[4].num = res.result.count;
      });
    },
    //获取日在线设备数(近一个月)
    getMonthTerminalData() {
      queryMonthDeviceTotal().then((res) => {
        const xData = [],
          yData = [];
        res.result.forEach((item) => {
          xData.push(item.date);
          yData.push(item.total);
        });
        this.terminalMonthChart.xData = xData;
        this.terminalMonthChart.yData = [
          {
            data: yData,
            type: "line",
          },
        ];
        this.terminalMonthChart.show = true;
      });
    },
    // 获取日接收数据量（近一个月）
    getMonthReceiveData() {
      queryMonthDataTotal().then((res) => {
        const xData = [],
          yData = [];
        res.result.forEach((item) => {
          xData.push(item.date);
          yData.push(item.total);
        });
        this.receiveMonthChart.xData = xData;
        this.receiveMonthChart.yData = [
          {
            data: yData,
            type: "line",
          },
        ];
        this.receiveMonthChart.show = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home-page {
  padding: 24px;
  height: calc(100% - 48px);
  overflow: auto;
  display: flex;
  flex-direction: column;

  .com-top {
    height: 110px;
    display: flex;
    .total-box {
      flex: 1;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:not(:last-child) {
        margin-right: 24px;
      }

      .title {
        font-size: 24px;
      }

      .num {
        font-size: 20px;
      }
    }
  }

  .com-bottom {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    padding: 24px;
    background: #fff;

    .line {
      // flex: 1;
      height: 240px;
      display: flex;

      .item {
        flex: 1;
      }
    }

    .bottom-dashed {
      display: flex;
      flex: 2;
      flex-direction: column;
      border: 1px dashed #c0c0c0;
      .top-condition {
        padding: 16px 24px;
        border-bottom: 1px dashed #c0c0c0;
      }
    }
  }
  .padding-left24 {
    padding-left: 24px;
  }
  .padding-right24 {
    padding-right: 24px;
  }
}
</style>
