<template>
  <div style="width: 100%; height: 100%">
    <echarts :chartId="chartId" :options="options"></echarts>
  </div>
</template>
<script>
import echarts from "@/components/echarts/main.vue";
export default {
  name: "line-chart",
  components: { echarts },
  props: {
    chartId: {
      type: String,
      default: "chartId" + Math.floor(Math.random() * 90) + 10,
    },
    title: {
      type: String,
      default: "",
    },
    unit: {
      type: String,
      default: "",
    },
    xAxisData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    yAxisData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data: () => ({
    options: {},
  }),
  created() {
    this.options = {
      title: {
        text: this.title,
        top: "3%",
        left: "center",
      },
      tooltip: {
        trigger: "axis",
      },
      grid: {
        bottom: 30,
        left: 30,
        right: 0
      },
      xAxis: {
        type: "category",
        data: this.xAxisData,
      },
      yAxis: {
        type: "value",
        name: this.unit,
      },
      series: this.yAxisData,
    };
  },
};
</script>
<style scoped lang="scss">
</style>