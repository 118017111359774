<template>
  <div class="echarts-legend" :id="chartId"></div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "echarts",
  props: {
    chartId: {
      type: String,
      default: "chartId" + Math.floor(Math.random() * 90) + 10,
    },
  },
  data: () => ({}),
  computed: {
    options() {
      const o = this.$attrs.options || {};
      return o;
    },
  },
  methods: {
    init() {
      const echartsApp = echarts.init(document.getElementById(this.chartId));
      const op = this.options;
      echartsApp.setOption(op);
      window.addEventListener("resize", function () {
        echartsApp.resize();
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped lang="scss">
.echarts-legend {
  width: 100%;
  height: 100%;
}
</style>
