import http from "@/axios/index";
import { URI } from "@/config/axios";

// 获取日接收数据量（近一个月）
export const getReceiveDayData = (d = {}) => {
  return http({
    url: URI.system + "api/home/terminal/day",
    method: "get",
  });
};

// 获取设备七日内日均在线时长
export const getTerminalSevenDayData = (d = {}) => {
  return http({
    url: URI.system + "api/home/terminal/sevenDay",
    method: "get",
  });
};

// 获取网关负载情况
export const getGatewayLoadData = (d = {}) => {
  return http({
    url: URI.system + "api/home/gateway/load",
    method: "get",
  });
};

//获取设备总数
export const getDeviceTotalData = () => {
  return http({
    url: URI.system + "api/tenantGateway/queryDevicecTotal",
    method: "get",
  });
};

//获取在线设备总数
export const getDeviceActiveTotal = () => {
  return http({
    url: URI.system + "api/tenantGateway/queryDevicecActiveTotal",
    method: "get",
  });
};

//获取资产总数
export const queryAssetsTotal = () => {
  return http({
    url: URI.system + "api/tenantGateway/queryAssetTotal",
    method: "get",
  });
};

//获取网关总数
export const queryGatewayTotal = () => {
  return http({
    url: URI.system + "/api/tenantGateway/queryTenantGatewayTotal",
    method: "get",
  });
};

//获取运行网关总数
export const queryGatewayActiveTotal = () => {
  return http({
    url: URI.system + "api/tenantGateway/queryTenantGatewayActiveTotal",
    method: "get",
  });
};

//获取日在线设备数(近一个月)
export const queryMonthDeviceTotal = () => {
  return http({
    url:
      URI.system +
      "api/tenantDeviceActiveStatistics/queryMonthDevicecActiveTotal",
    method: "get",
  });
};

// 获取日接收数据量（近一个月）
export const queryMonthDataTotal = () => {
  return http({
    url: URI.system + "api/dataStatistics/queryMonthDataTotal",
    method: "get",
  });
};
